import Glider from 'glider-js';

$(document).ready(() => {
    let carousel = document.querySelector('.js-product-carousel-category')
    if(carousel){
        new Glider(carousel, {
            slidesToShow: 1,
            slidesToScroll: 1,
            scrollLock: true,
            arrows: {
                prev: '.js-product-carousel-category-prev',
                next: '.js-product-carousel-category-next',
            },
            responsive: [
                {
                    // screens greater than >= 775px
                    breakpoint: 775,
                    settings: {
                        // Set to `auto` and provide item width to adjust to viewport
                        slidesToShow: 2,
                        slidesToScroll: 'auto',
                        itemWidth: 150,
                        duration: 0.25
                    }
                },{
                    // screens greater than >= 1024px
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        itemWidth: 150,
                        duration: 0.25
                    }
                }
            ]
        });
    }

});
